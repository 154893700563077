<template>
  <div class="third_form_box">
    <vh-form class="third_form" label-position="left" :model="thirdFormConfig" ref="thirdForm">
      <vh-form-item label="三方表单嵌入" label-width="110px">
        <vh-input type="textarea" v-model="thirdFormConfig.form_content"></vh-input>
      </vh-form-item>
      <vh-form-item label="昵称关联字段" label-width="110px">
        <vh-input v-model.trim.number="thirdFormConfig.nickname_field"></vh-input>
      </vh-form-item>
      <vh-button class="save_btn" @click="saveThirdFormConfig" type="primary">保存</vh-button>
    </vh-form>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        thirdFormConfig: {
          form_content: '',
          nickname_field: ''
        }
      };
    },
    methods: {
      getThirdForm() {
        this.$fetch('getThirdFormConfig', { webinar_id: this.$route.params.str }).then(res => {
          if (res.code == 200) {
            this.thirdFormConfig = res.data;
          }
        });
      },
      saveThirdFormConfig(e) {
        e.preventDefault();
        this.$fetch(
          'saveThirdFormConfig',
          Object.assign({ webinar_id: this.$route.params.str }, this.thirdFormConfig)
        ).then(res => {
          if (res.code == 200) {
            this.$message.success('保存成功');
          } else {
            this.$message.success('保存失败');
          }
        });
      }
    },
    mounted() {
      this.getThirdForm();
    }
  };
</script>
<style scoped lang="less">
  .third_form_box {
    padding: 49px 56px 40px 56px;
    .third_form {
      width: 600px;
      /deep/ .vh-textarea textarea {
        height: 200px;
      }
    }
    .save_btn {
      display: block;
      margin: 40px 0 0 110px;
    }
  }
</style>
